import moment from 'moment';

$.validator.addMethod('date', function (value, element, params) {
	if (this.optional(element)) {
		return true;
	}

	try {
		moment(value, 'DD/MM/YYYY');
		return true;
	} catch (err) { }

	return false;
}, '');

////re-set all client validation given a jQuery selected form or child
//$.fn.resetValidation = function () {

//	var $form = this.closest('form');

//	//reset jQuery Validate's internals
//	$form.validate().resetForm();

//	//reset unobtrusive validation summary, if it exists
//	$form.find("[data-valmsg-summary=true]")
//		.removeClass("validation-summary-errors")
//		.addClass("validation-summary-valid")
//		.find("ul").empty();

//	//reset unobtrusive field level, if it exists
//	$form.find("[data-valmsg-replace]")
//		.removeClass("field-validation-error")
//		.addClass("field-validation-valid")
//		.empty();

//	return $form;
//};

ko.validation.init({
	insertMessages: false,
	decorateInputElement: true,
	errorElementClass: 'has-error'
});
