ko.bindingHandlers.percent = {
	init: (element, valueAccessor, allBindingsAccessor) => {
		var $element = $(element);
		var precision = allBindingsAccessor().precision;
		if (precision !== undefined) {
			$element.data('precision', precision);
		} else {
			$element.data('precision', 1);
		}
		$element.data('suffix', '%');
	},
	update: (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) => ko.bindingHandlers.float.update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext)
};