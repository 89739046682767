function SplitWords(input) {
	if (!input) { return input; }
	return input.replace(/([A-Z])/g, " $1").replace(/^\s+/, "");
};

ko.virtualElements.allowedBindings['splitText'] = true;
ko.bindingHandlers.splitText = {
	update: (element, valueAccessor) => {
		var value = ko.unwrap(valueAccessor());
		if (value !== null) {
			ko.bindingHandlers.text.update(element, () => SplitWords(value));
		}
	}
};