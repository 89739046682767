ko.bindingHandlers.bool = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());

		var trueValue = 'fa-check';
		var falseValue = 'fa-times';

		if (value !== null && typeof value === 'object') {
			if (value['trueIcon'] !== undefined) {
				trueValue = value['trueIcon'];
			}
			if (value['falseIcon'] !== undefined) {
				falseValue = value['falseIcon'];
			}

			if (value['color']) {
				trueValue += ' text-success';
				falseValue += ' text-danger';
			}

			value = ko.unwrap(value['data']);
		}

		if (value !== null) {
			if (value === true && trueValue) {
				$(element).html(`<i class='fal ${trueValue}'></i>`);
			} else if (value === false && falseValue) {
				$(element).html(`<i class='fal ${falseValue}'></i>`);
			}
		}
	}
};