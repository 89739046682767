export namespace AjaxForms {

	interface IValidationError {
		PropertyName: string;
		Errors: string[]
	}

	export function FindOrCreateValidationElement(formElement: HTMLFormElement) {
		// Find (or add) the validation summary element within the form
		var summaryElement = $(formElement).find("[data-valmsg-summary=true]");
		if (summaryElement.length === 0) {
			summaryElement = $('<div>')
				.attr('class', 'validation-summary-valid')
				.attr('data-valmsg-summary', 'true');

			// If this form contains a modal-body, put the summary in there instead
			var modalBodyElement = $(formElement).find(".modal-body");
			if (modalBodyElement.length !== 0) {
				$(modalBodyElement).prepend(summaryElement);
			} else {
				$(formElement).prepend(summaryElement);
			}
		}

		// Find (or add) a UL to hold the validation errors
		var ul = summaryElement.find("ul:first-child");
		if (ul.length === 0) {
			ul = $('<ul>');
			summaryElement.prepend(ul);
		}

		return ul;
	}

	/// <summary>
	/// Removes any validation error markup from the form before re-processing the response from the post
	/// </summary>
	export function ClearValidationErrors(formElement: HTMLFormElement, summaryElement) {
		summaryElement.children().remove();
		summaryElement.parent()
			.removeClass('validation-summary-errors')
			.removeClass('validation-summary-system-error')
			.addClass('validation-summary-valid');
		$('.input-validation-error', $(formElement)).removeClass('input-validation-error');

		var controlGroups = $('.control-group', $(formElement));
		controlGroups.removeClass('error').removeClass('warning').removeClass('success');
		$('.help-inline', controlGroups).text('');
	}

	export class AjaxResponseError {
		constructor(err: any) {
			this.StatusCode = err.status;

			var response: IValidationErrorItem[];
			if (err.responseJSON && err.responseJSON.Message) {
				try {
					response = JSON.parse(err.responseJSON.Message);
				} catch (ex) {
					response = [{ Errors: [err.responseJSON.Message], PropertyName: null }];
				}
			} else {
				response = err.responseJSON;
			}

			if (response && Array.isArray(response)) {
				this.Messages = response;

				if (this.Messages.length === 1 && this.Messages[0].Errors.length === 1) {
					this.Message = this.Messages[0].Errors[0];
					if (err.responseJSON && err.responseJSON.ExceptionMessage) {
						this.Message += " " + err.responseJSON.ExceptionMessage;
					}
				} else {
					if (this.StatusCode === 400) {
						this.Message = 'One or more problems were found';
					}
				}
			} else {
				this.Message = 'An error occurred';
				if (err.responseJSON && err.responseJSON.ExceptionMessage) {
					this.Message += ": " + err.responseJSON.ExceptionMessage;
				}
			}
		}

		public StatusCode: number;
		public IsValidationError: boolean;
		public Message: string;
		public Messages: IValidationErrorItem[];
	}

	export interface IValidationErrorItem {
		Errors: string[];
		PropertyName: string;
	}

	export function HandleErrorFormResponse(err, formElement, summaryElement) {
		var elem;
		var errorResponse = new AjaxResponseError(err);

		if (errorResponse.StatusCode === 400) {
			errorResponse.Messages.forEach(item => {
				item.Errors.forEach(msg => {
					summaryElement.append($('<li>').append(msg));

					if (item.PropertyName !== '') {
						// Add a validation failure for the element with this name
						elem = $(formElement).find(`input#${item.PropertyName},textarea#${item.PropertyName}`);
						if (elem.length) {
							elem.addClass('input-validation-error');

							var controlGroup = elem.closest('.control-group');
							controlGroup.addClass('error');
							$('.help-inline', controlGroup).text(msg);
						}
					}
				});
			});

			// Cause the validation summary to be shown
			summaryElement.parent().removeClass('validation-summary-valid').addClass('validation-summary-errors');
		} else {
			// Display an error message indicating the form wasn't submitted
			console.log('Error POSTing', err);

			summaryElement.append($('<li>').append(errorResponse.Message));
			summaryElement.parent()
				.removeClass('validation-summary-valid')
				.addClass('validation-summary-errors')
				.addClass('validation-summary-system-error');

			if (summaryElement.length === 0) {
				toastr.error(errorResponse.Message);
			} else {
				// Cause the validation summary to be shown
				summaryElement.parent().removeClass('validation-summary-valid').addClass('validation-summary-errors');
			}
		}
	}

	/// <summary>
	/// Handles an error response from an AJAX form post.  We're assuming that the post was to submit the form, so
	/// this function handles an error coming back.  If the status code is 400, this must be a validation error,
	/// and the response message contains enough information to present this back to the user.  Otherwise, the
	/// message will be displayed back to the user
	/// </summary>
	export function HandleAjaxError(err) {
		var formElement = this;
		var summaryElement = FindOrCreateValidationElement(formElement);

		ClearValidationErrors(formElement, summaryElement);
		HandleErrorFormResponse(err, formElement, summaryElement);
	}

	export function HandleFormResponse(result, formElement: HTMLFormElement) {
		var summaryElement = FindOrCreateValidationElement(formElement);

		// Clear any existing errors
		ClearValidationErrors(formElement, summaryElement);

		// Is this an error?
		if (result && result.status && result.status >= 400) {
			HandleErrorFormResponse(result, formElement, summaryElement);
		} else {
			// Success!
		}
	}
}

globalThis.AjaxForms = AjaxForms;