import moment from "moment";

ko.bindingHandlers.timeSpan = {
	update: function (element, valueAccessor) {
		var value = ko.unwrap(valueAccessor());

		var output = '';
		if (value !== null && value !== undefined && value.length > 0) {
			var duration = moment.duration(value);
			if (duration.days() >= 1) {
				output += `${duration.days()}d, `;
			}
			if (duration.hours() >= 1) {
				output += `${duration.hours()}h `;
			}
			output += `${duration.minutes()}m`;
			output = output.trim();
		}

		if ($(element).is('input')) {
			$(element).val(output);
		} else {
			$(element).text(output);
		}
	}
};