// https://github.com/uudashr/jquery-linkify/blob/master/jquery.linkify.js

function linkify(string, buildHashtagUrl, includeW3, target) {
    // Turn existing ticket URLs into Ticket IDs
    var re = new RegExp("https://[A-Za-z]+\.[A-Za-z]+\.[A-Za-z]+/[Tt]ickets/([A-Za-z]{3}\-[0-9]{6})", "gi");
    string = string.replace(re, "$1")
    
    string = string.replace(/((http|https|ftp)\:\/\/|\bw{3}\.)[a-z0-9\-\.]+\.[a-z]{2,3}(:[a-z0-9]*)?\/?([a-z\u00C0-\u017F0-9\-\._\?\,\'\/\\\+&amp;%\$#\=~])*/gi, function (captured) {
        var uri;
        if (captured.toLowerCase().indexOf("www.") === 0) {
            if (!includeW3) {
                return captured;
            }
            uri = "http://" + captured;
        } else {
            uri = captured;
        }
        return "<a href=\"" + uri + "\" target=\"" + target + "\">" + captured + "</a>";
    });

    if (buildHashtagUrl) {
        string = string.replace(/\B#(\w+)/g, "<a href=" + buildHashtagUrl("$1") + " target=\"" + target + "\">#$1</a>");
    }

    // Build Ticket URL
    string = string.replace(/\b[A-Za-z]{3}\-[0-9]{6}\b/g, function (captured) {
        if (captured.match(/^(TIC-|ILK-|SFL-)/)) {      // Exclude Telehouse Cross Connect format
            return captured;
        }
        return "<a href=\"/Tickets/" + captured + "\" target=\"" + target + "\">" + captured + "</a>";
    });

    return string;
}

(function ($) {
    $.fn.linkify = function (opts) {
        return this.each(function () {
            var $this = $(this);
            var buildHashtagUrl;
            var includeW3 = true;
            var target = '_self';
            if (opts) {
                if (typeof opts === "function") {
                    buildHashtagUrl = opts;
                } else {
                    if (typeof opts.hashtagUrlBuilder === "function") {
                        buildHashtagUrl = opts.hashtagUrlBuilder;
                    }
                    if (typeof opts.includeW3 === "boolean") {
                        includeW3 = opts.includeW3;
                    }
                    if (typeof opts.target === "string") {
                        target = opts.target;
                    }
                }
            }

            $this.html(linkify($this.html(), buildHashtagUrl, includeW3, target));

            //$this.html(
            //    $.map(
            //      $this.contents(),
            //      function (n, i) {
            //          if (n.nodeType === 3) {
            //              return linkify(n.data, buildHashtagUrl, includeW3, target);
            //          } else {
            //              return n.outerHTML;
            //          }
            //      }
            //  ).join("")
            //);
        });
    };
})(jQuery);