import './KoBindings/commandbutton';
import './KoBindings/loadingwhen';
import './KoBindings/numberwithsuffix';

export interface PartialViewPagedListOptions {
	pageSize?: number;
	onPageLoaded?: (result: IPagedListPartialViewModel) => void;
	initialData?: any;
}

interface IPagedListFilterModel {
	SortExpression: string;
	SortDescending: boolean;
	PageSize: number;
	PageNumber: number;
}

interface IPagedListPartialViewModel {
	Filter: IPagedListFilterModel;
	TotalRecords: number;
	RenderedList: string;
}

/// <summary>
/// The PartialViewPagedList is a knockout function which uses server-side rendering of the paged data along with
/// some metadata
/// </summary>
export class PartialViewPagedList {
	constructor(private dataUrl: string, private target, private options?: PartialViewPagedListOptions) {
		// Validate parameters
		if (!dataUrl || dataUrl === '') {
			throw "Invalid dataUrl property";
		}
		if (!target) {
			throw "Invalid target";
		}
		options = options || { pageSize: 10 };

		this.PageSize(options.pageSize || 10);

		if (options.initialData) {
			ko.mapping.fromJS(options.initialData, {}, this);
		}
	}

	private isLoading: boolean;

	Filter: KnockoutObservable<IPagedListFilterModel> = ko.observable(null);

	TotalRecords: KnockoutObservable<number> = ko.observable(0);
	PageSize: KnockoutObservable<number> = ko.observable(null);
	PageNumber: KnockoutObservable<number> = ko.observable(1);
	Offset: KnockoutObservable<string> = ko.observable(null);
	StartIndex: KnockoutObservable<number> = ko.observable(null);
	PageRecordCount: KnockoutObservable<number> = ko.observable(null);

	NextPageOffset: KnockoutObservable<string> = ko.observable(null);
	PrevPageOffset: KnockoutObservable<string> = ko.observable(null);

	StartRecord = ko.pureComputed(() => this.StartIndex() + 1);
	EndRecord = ko.pureComputed(() => this.StartIndex() + this.PageRecordCount());
	TotalPages = ko.pureComputed(() => Math.ceil(this.TotalRecords() / this.PageSize()));

	EnableAutoRefresh = () => window.setTimeout(this.Refresh, 30000);
	Refresh = () => {
		if (!this.isLoading) {
			if (this.PageNumber() === 1) {
				// We only want to auto-refresh on the first page
				this.LoadPage(1, null);
			} else if (!this.PageNumber() && (!this.Offset() || this.Offset() == '0')) {
				this.LoadPage(null, '');
			}
		}

		window.setTimeout(this.Refresh, 30000);
	};

	NextPage = ko.command({
		action: async () => {
			if (this.PageNumber()) {
				const newPage = this.PageNumber() + 1;
				await this.LoadPage(newPage, null);
			} else if (this.NextPageOffset()) {
				await this.LoadPage(null, this.NextPageOffset());
			}
		},
		canExecute: () => (!!this.PageNumber() && this.PageNumber() < this.TotalPages()) || !!this.NextPageOffset()
	});

	PrevPage = ko.command({
		action: async () => {
			if (this.PageNumber()) {
				const newPage = this.PageNumber() - 1;
				await this.LoadPage(newPage, null);
			} else if (this.PrevPageOffset()) {
				await this.LoadPage(null, this.PrevPageOffset());
			}
		},
		canExecute: () => (this.PageNumber && this.PageNumber() > 1) || !!this.PrevPageOffset()
	});

	LoadPage = async (newPageNumber: number, newOffset: string) => {
		if (this.isLoading) { return; }
		this.isLoading = true;

		const postData = ko.mapping.toJS(this.Filter());
		if (newPageNumber !== null) {
			postData.PageNumber = newPageNumber;
		}
		if (newOffset !== null) {
			postData.Offset = newOffset;
		}

		try {
			const result: IPagedListPartialViewModel = await window.ajaxRequest('POST', this.dataUrl, postData);
			ko.mapping.fromJS(result as unknown, {}, this);

			this.target.html(result.RenderedList);
			if (this.options.onPageLoaded) {
				this.options.onPageLoaded(result);
			}
		} catch (err) {
			toastr.error(err);
		} finally {
			this.isLoading = false;
		}
	};
}