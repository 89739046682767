import moment from "moment";

ko.bindingHandlers.fromNow = {
	init: () => { },
	update: (element, valueAccessor) => {
		var value = ko.unwrap(valueAccessor());
		if (value) {
			var formattedValue = moment(value).fromNow();
			ko.bindingHandlers.html.update(element, () => formattedValue);
		}
	}
};