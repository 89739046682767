import ClipboardJS from "clipboard";
import toastr from "toastr";
import 'floatthead';
import autosize from 'autosize';
import 'select2';
import { PartialViewPagedList } from "./PartialViewPagedList";

class FetchError {
	constructor(response: Response, body: any) {
		this.status = response.status;
		this.statusText = response.statusText;
		this.responseJSON = body;
	}

	public status: number;
	public statusText: string;
	public responseJSON: any;
}

//#region Window Extensions

window.antiForgeryToken = () => $("input[name=__RequestVerificationToken]").val();

window.ajaxRequestFetch = async (verb: string, url: string, data?: any): Promise<any> => {
	const request: RequestInit = {
		method: verb,
		headers: {
			'Content-Type': 'application/json'
		}
	};

	if (window.antiForgeryToken) {
		const token = typeof window.antiForgeryToken === 'function' ? window.antiForgeryToken() : window.antiForgeryToken;
		if (token) {
			request.headers['X-XSRF-Token'] = token;
		}
	}

	if (data !== null) {
		request.body = ko.toJSON(data);
	}

	try {
		const response = await fetch(url, request);
		if (!response.ok) {
			const body = await response.json();
			throw new FetchError(response, body);
		}
		if (response.status == 204) {		// Don't try and decode no content
			return null;
		}
		return await response.json();
	} catch (err) {
		console.log('Request Failed', err);
		return Promise.reject(err);
	}
}

window.ajaxRequest = (verb: string, url: string, data?: any, dataType?: string, cache?: boolean, disableError = true): JQueryPromise<any> => {
	const settings: JQueryAjaxSettings = {
		dataType: dataType || "json",
		contentType: "application/json",
		cache: cache || false,
		type: verb,
		data: data ? ko.toJSON(data) : null
	};

	// CSRF Protection
	if (window.antiForgeryToken) {
		const token = typeof window.antiForgeryToken === 'function' ? window.antiForgeryToken() : window.antiForgeryToken;
		if (token) {
			settings.headers = settings.headers || {};
			settings.headers['X-XSRF-Token'] = token
		}
	}

	const promise = $.ajax(url, settings)

	if (!disableError) {
		promise.fail((xhr: JQueryXHR, textStatus: string, errorThrown: string) => {
			toastr.error(errorThrown, 'Request Failed');
		});
	}

	return promise;
};

window.bindKoModel = (model: any, target: boolean | any) => {
	if (target === undefined || target === false) {
		target = document.body;
	} else if (target === true) {
		target = document.getElementById('popupModal');
	}

	ko.applyBindings(model, target);
	window.OnRenderComplete();
}
window.bindKoModelForPopup = (model: any) => window.bindKoModel(model, true);
window.getPageModel = () => ko.dataFor(document.body);
window.getPopupModel = () => ko.dataFor(document.getElementById('popupModal'));

window.initPageModel = async (path: string, ...args: any[]) => {
	console.debug('Import', path);

	const m = !path.startsWith('common/app/')
		? await import(`../../app/${path}`)
		: await import(`./${path.substring('common/app/'.length)}`);
	if (!m) {
		toastr.warning('Nothing returned from import', path);
		return;
	}

	if (m['PageModelFactory'] && typeof (m['PageModelFactory']) === 'function') {
		await m.PageModelFactory(...args);
	} else if (typeof (m) === 'function') {
		await m(...args);
	} else {
		toastr.warning('initPageModel did not get a function in response', m);
	}
};

async function initNamedPageModelCore(target: any, path: string, viewModelName: string, ...args: any[]) {

	console.log('Import', !path.startsWith('common/app/')
		? `../../app/${path}`
		: `./${path.substring('common/app/'.length)}`);

	const m = !path.startsWith('common/app/')
		? await import(`../../app/${path}`)
		: await import(`./${path.substring('common/app/'.length)}`);
	if (!m) {
		toastr.warning('Nothing returned from import', path);
		return;
	}

	const viewModelClass = m[viewModelName];
	if (!viewModelClass) {
		toastr.warning('ViewModel Accessor returned null after importing', path);
		return;
	}

	const vm = new viewModelClass(...args);
	window.bindKoModel(vm, target);
};

async function initPagedListViewModel(target: any, url: string, data: any, autoRefresh?: boolean) {
	var vm = {
		pagedList: new PartialViewPagedList(url, target, {
			initialData: data,
			onPageLoaded: window.OnRenderComplete
		})
	};
	if (autoRefresh) {
		vm.pagedList.EnableAutoRefresh();
	}
	window.bindKoModel(vm);
	return vm;
}

window.initNamedPageModelForTarget = async (target: any, path: string, viewModelName: string, ...args: any[]) =>
	initNamedPageModelCore(target, path, viewModelName, ...args);
window.initNamedPageModelPopup = async (path: string, viewModelName: string, ...args: any[]) =>
	initNamedPageModelCore(true, path, viewModelName, ...args);
window.initNamedPageModel = async (path: string, viewModelName: string, ...args: any[]) =>
	initNamedPageModelCore(false, path, viewModelName, ...args);
window.initPagedListViewModel = initPagedListViewModel;

// Loading button plugin (removed from BS4)
(function ($) {
	$.fn.buttonstate = function (action) {
		if (action === 'loading') {
			const loadingText = this.data('loading-text')
				|| `<div class="spinner-border spinner-border-sm" role="status"></div> ${(this.text().trim().length > 0 ? "Loading&hellip;" : "")}`;
			this.addClass('disabled');
			this.data('original-text', this.html()).html(loadingText).prop('disabled', true);
		}
		if (action === 'reset' && this.data('original-text')) {
			this.html(this.data('original-text')).prop('disabled', false);
			this.removeClass('disabled');
		}
	};
}(jQuery));

$(document).on('select2:open', () => {
	const allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
	(allFound[allFound.length - 1] as HTMLInputElement).focus();
});

window.OnRenderComplete = () => {
	$("table.table-fullrowselect").linkWholeRows();
	if (!$("div.main-content").hasClass("disable-floatthead")) {
		$("table.table-floatthead").floatThead({
			top: 66,
			responsiveContainer: ($table) => $table.closest('.table-responsive')
		});
	}
	autosize($(".autosize"));

	$('.s2:not([multiple])').select2({ theme: 'bootstrap4', closeOnSelect: true });
	$('.s2[multiple]').select2({ theme: 'bootstrap4', closeOnSelect: false });

	$('#popupModal .s2:not([multiple])').select2({ theme: 'bootstrap4', closeOnSelect: true, dropdownParent: $('#popupModal') });
	$('#popupModal .s2[multiple]').select2({ theme: 'bootstrap4', closeOnSelect: false, dropdownParent: $('#popupModal') });



	$('[data-toggle="tooltip"]').tooltip();

	$("textarea.allow-tab").on('keydown', function (e: KeyboardEvent) {
		if (e.keyCode === 9) { // tab was pressed
			// get caret position/selection
			const input = this as HTMLTextAreaElement;
			const start = input.selectionStart;
			const end = input.selectionEnd;

			// set textarea value to: text before caret + tab + text after caret
			input.value = input.value.substring(0, start) + "\t" + input.value.substring(end);

			// put caret at right position again
			input.selectionStart = input.selectionEnd = start + 1;

			// prevent the focus lose
			return false;
		}
	});
};

window.onerror = function (eventOrMessage: any, source: string, fileno: number, colnumber?: number): any {
	const message = `${eventOrMessage}\nLine ${fileno} of ${source}`;
	toastr.error(message.replace('\n', '<br />'), 'Unhandled Error');
	//$.post("@Url.Action("LogClientError", "Home", new { area = "" })", { message: message });
	return false;
};

//#endregion

//#region String Extensions

String.prototype.startsWith = function (prefix: string) {
	return this.indexOf(prefix) === 0;
};

String.prototype.endsWith = function (suffix: string) {
	return this.indexOf(suffix, this.length - suffix.length) !== -1;
}

//#endregion

if (!Array.prototype.find) {
	Array.prototype.find = function (predicate) {
		if (this === null) {
			throw new TypeError('Array.prototype.find called on null or undefined');
		}
		if (typeof predicate !== 'function') {
			throw new TypeError('predicate must be a function');
		}
		const list = Object(this);
		const length = list.length >>> 0;
		const thisArg = arguments[1];

		for (let i = 0; i < length; i++) {
			const value = list[i];
			if (predicate.call(thisArg, value, i, list)) {
				return value;
			}
		}
		return undefined;
	};
}

Number.prototype.format = function (n, x) {
	const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
	return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

const clipboard = new ClipboardJS('.copy-text');
window["clipboardjs"] = clipboard;
window["clipboard_onsuccess"] = function (e) {
	e.clearSelection();

	const $el = $(e.trigger)
	$el.tooltip({ title: 'Copied', trigger: 'manual' });
	$el.tooltip('show');
	window.setTimeout(() => $el.tooltip('dispose'), 2000);
};

clipboard.on('success', window["clipboard_onsuccess"]);

$(document).ready(function () {
	var chkboxShiftLastChecked = [];
	var lock = false;

	$(document).on('click', '[data-chkbox-shiftsel]', function (e) {
		if (lock) { return; }

		try {
			lock = true;

			var chkboxType = $(this).data('chkbox-shiftsel');
			if (chkboxType === '') {
				chkboxType = 'default';
			}
			var $chkboxes = $('[data-chkbox-shiftsel="' + chkboxType + '"]');

			if (!chkboxShiftLastChecked[chkboxType]) {
				chkboxShiftLastChecked[chkboxType] = this;
				return;
			}

			if (e.shiftKey) {
				var start = $chkboxes.index(this);
				var end = $chkboxes.index(chkboxShiftLastChecked[chkboxType]);

				if (start > end) {
					var swap = start;
					start = end;
					end = swap;
				}

				var updateKo = !!$(this).data('bind');
				var targetValue = chkboxShiftLastChecked[chkboxType].checked;

				for (let i = start + 1; i < end; i++) {
					const $chk = $($chkboxes[i]);

					if ($chk.prop('checked') !== targetValue) {
						if (updateKo) {
							$chk.trigger('click');
						} else {
							$chk.prop('checked', targetValue);
						}
					}
				}
			}
			chkboxShiftLastChecked[chkboxType] = this;
		} finally {
			lock = false;
		}
	});
});