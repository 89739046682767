ko.bindingHandlers.radio = {
	init: function (element, valueAccessor, allBindings) {
		var $buttons,
			$element,
			observable = valueAccessor(),
			elementBindings;

		if (!ko.isWriteableObservable(observable)) {
			throw "You must pass an observable or writeable computed";
		}
		$element = $(element);
		if ($element.hasClass("btn")) {
			$buttons = $element;
		} else {
			$buttons = $(".btn", $element);
		}

		if (!$buttons.hasClass('btn-default')) {
			$buttons.addClass('btn-default');
		}

		elementBindings = allBindings();
		$buttons.each(function () {
			var $btn, btn, radioValue;
			btn = this;
			$btn = $(btn);
			radioValue = elementBindings.radioValue || $btn.attr("data-value") || $btn.attr("value") || $btn.text();
			$btn.on("click", () => observable(ko.unwrap(radioValue)));
			return ko.computed({
				disposeWhenNodeIsRemoved: btn,
				read: function () {
					$btn.toggleClass("active", observable() === ko.unwrap(radioValue));
				}
			});
		});
	}
};