ko.bindingHandlers.commandButton = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		let command = ko.unwrap(valueAccessor());
		command = command.fail((err) => console.log(err));

		ko.bindingHandlers.loadingWhen.init.call(this, element, command.isRunning, allBindingsAccessor);
		ko.bindingHandlers.click.init.call(this, element, ko.observable(command), allBindingsAccessor, viewModel, bindingContext);
	},
	update: function (element, valueAccessor, allBindingsAccessor) {
		const command = ko.unwrap(valueAccessor());
		ko.bindingHandlers.loadingWhen.update.call(this, element, command.isRunning, allBindingsAccessor);
		ko.bindingHandlers.enable.update.call(this, element, command.canExecute, allBindingsAccessor);
	}
};