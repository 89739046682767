import ClipboardJS from 'clipboard';

ko.bindingHandlers.showModal = {
	init: (element: any, valueAccessor: () => any) => {
		$(element).modal({ show: false });

		var value = valueAccessor();
		if (ko.isObservable(value)) {
			$(element).on('hide.bs.modal', (e) => {

				// Prevent closing a nested modal from closing all open modals
				if (e.target !== element) { return; }

				if (ko.isWriteableObservable(value)) {
					var target = ko.unwrap(value);
					if (target && ko.isWriteableObservable(target.IsVisible)) {
						target.IsVisible(false);
					}

					value(null);
				}
			});

			$(element).on('show.bs.modal', (e) => {
				var clipboard = new ClipboardJS('.copy-text', { container: element });
				clipboard.on('success', window["clipboard_onsuccess"]);
			});
		}
	},
	update: (element: any, valueAccessor: () => any) => {
		var value = valueAccessor(),
			target = ko.unwrap(value);

		if (target) {
			$(element).modal('show');
			//$('input,select', element).focus();

			if (ko.isWriteableObservable(target.IsVisible)) {
				target.IsVisible(true);
			}
		} else {
			$(element).modal('hide');
		}
	}
}