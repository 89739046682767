/**
 * This is a customised version of the jquery unobtrusive ajax library.  The only modification is the specialised
 * handling of the "button" element which causes the ajax request to be initiated.  The bootstrap button library
 * is called to change the button to a Loading state while the request is completing.  There is no callback
 * mechanism currently available to add this functionality without modifying the source file.
 */
(function ($) {
    var data_click = "unobtrusiveAjaxClick",
        data_target = "unobtrusiveAjaxClickTarget",
        data_validation = "unobtrusiveValidation";

    function getFunction(code, argNames) {
        var fn = window, parts = (code || "").split(".");
        while (fn && parts.length) {
            fn = fn[parts.shift()];
        }
        if (typeof (fn) === "function") {
            return fn;
        }
        argNames.push(code);
        return Function.constructor.apply(null, argNames);
    }

    function isMethodProxySafe(method) {
        return method === "GET" || method === "POST";
    }

    function asyncOnBeforeSend(xhr, method) {
        if (!isMethodProxySafe(method)) {
            xhr.setRequestHeader("X-HTTP-Method-Override", method);
        }
    }

    function asyncOnSuccess(element, data, contentType) {
        var mode;

        if (contentType.indexOf("application/x-javascript") !== -1) {  // jQuery already executes JavaScript for us
            return;
        }
        mode = (element.getAttribute("data-ajax-mode") || "").toUpperCase();
        $(element.getAttribute("data-ajax-update")).each(function (i, update) {
            var top;

            switch (mode) {
                case "BEFORE":
                    top = update.firstChild;
                    $("<div />").html(data).contents().each(function () {
                        update.insertBefore(this, top);
                    });
                    break;
                case "AFTER":
                    $("<div />").html(data).contents().each(function () {
                        update.appendChild(this);
                    });
                    break;
                case "REPLACE-WITH":
                    if (window.ko) {
                        ko.cleanNode(update);
                    }
                    $(update).replaceWith(data);
                    break;
                default:
                    const modal = $(update).data('bs.modal');
                    if (window.ko) {
                        // Remove knockout bindings so applyBindings can be re-applied.
                        // This has the unfortunate side effect of removing dom data used by modal, so we attempt to restore it
                        ko.cleanNode(update);
                    }
                    $(update).html(data);
                    if (modal) {
                        modal._dialog = $($(update), '.modal-dialog')[0];
                        $(update).data('bs.modal', modal);

                        // Restore dismiss handlers
                        const DATA_KEY = 'bs.modal';
                        const EVENT_KEY = '.' + DATA_KEY;
                        const EVENT_CLICK_DISMISS = 'click.dismiss' + EVENT_KEY;
                        const EVENT_KEYDOWN_DISMISS = 'keydown.dismiss' + EVENT_KEY;
                        const EVENT_MOUSEUP_DISMISS = 'mouseup.dismiss' + EVENT_KEY;
                        const EVENT_MOUSEDOWN_DISMISS = 'mousedown.dismiss' + EVENT_KEY;
                        const SELECTOR_DATA_DISMISS = '[data-dismiss="modal"]';
                        const ESCAPE_KEYCODE = 27;

                        // Duplication of handlers added in bootstrap modal
                        $(modal._element).on(EVENT_CLICK_DISMISS, SELECTOR_DATA_DISMISS, function (event) { modal.hide(); });
                        $(modal._dialog).on(EVENT_MOUSEDOWN_DISMISS, function() {
                            $(modal._element).one(EVENT_MOUSEUP_DISMISS, function(event) {
                                if ($(event.target).is(modal._element)) {
                                    modal._ignoreBackdropClick = true;
                                }
                            });
                        });

                        $(modal._element).on(EVENT_KEYDOWN_DISMISS, function(event) {
                            if (modal._config.keyboard && event.which === ESCAPE_KEYCODE) {
                                event.preventDefault()
                                modal.hide()
                            } else if (!modal._config.keyboard && event.which === ESCAPE_KEYCODE) {
                                modal._triggerBackdropTransition()
                            }
                        })

                        // TODO: Restore backdrop click handler
                        $(modal._element).on(EVENT_CLICK_DISMISS, function(event) {
                            if (modal._ignoreBackdropClick) {
                                modal._ignoreBackdropClick = false
                                return
                            }

                            if (event.target !== event.currentTarget) {
                                return
                            }

                            modal._triggerBackdropTransition();
                        });
                    }
                    if (window.OnRenderComplete) {
                        OnRenderComplete();
                    }
                    break;
            }
        });
    }

    function asyncRequest(element, options) {
        var confirm, loading, method, duration, button;

        confirm = element.getAttribute("data-ajax-confirm");
        if (confirm && !window.confirm(confirm)) {
            return;
        }

        loading = $(element.getAttribute("data-ajax-loading"));
        duration = parseInt(element.getAttribute("data-ajax-loading-duration"), 10) || 0;
        var $element = $(element);

        button = ($.fn.button !== undefined && $element.hasClass("btn")) ? $element : null;
        if ((!button || !button.length) && element && element.tagName === 'FORM') {
            // Try and find the button on the form
            button = $('button[type=submit]', $element);

            if (!button.length) {
                var modalFooter = $('.modal-footer', $element);
                if (modalFooter.length) {
                    if (!button.length) { button = $('input[type=submit]', modalFooter); }
                    if (!button.length) { button = $('button.btn-primary', modalFooter); }
                } else {
                    if (!button.length) { button = $('input[type=submit]', $element); }
                    if (!button.length) { button = $('button.btn-primary', $element); }
                }
            }
        }

        $.extend(options, {
            type: element.getAttribute("data-ajax-method") || undefined,
            url: element.getAttribute("data-ajax-url") || undefined,
            cache: !!element.getAttribute("data-ajax-cache"),
            beforeSend: function (xhr) {
                var result;
                asyncOnBeforeSend(xhr, method);
                result = getFunction(element.getAttribute("data-ajax-begin"), ["xhr"]).apply(element, arguments);
                if (result !== false) {
                    loading.show(duration);
                    if (window.ko) {
                        if (ko.isWriteableObservable(window.loading)) {
                            window.loading(window.loading() + 1);
                        }
                    }
                }
                if (button) {
                    button.buttonstate('loading');
                }
                return result;
            },
            complete: function () {
                loading.hide(duration);
                if (window.ko) {
                    if (ko.isWriteableObservable(window.loading)) {
                        window.loading(window.loading() - 1);
                    }
                }
                getFunction(element.getAttribute("data-ajax-complete"), ["xhr", "status"]).apply(element, arguments);
                if (button) {
                    button.buttonstate('reset');
                }
            },
            success: function (data, status, xhr) {
                asyncOnSuccess(element, data, xhr.getResponseHeader("Content-Type") || "text/html");
                getFunction(element.getAttribute("data-ajax-success"), ["data", "status", "xhr"]).apply(element, arguments);
            },
            error: function () {
                getFunction(element.getAttribute("data-ajax-failure"), ["xhr", "status", "error"]).apply(element, arguments);
            }
        });

        options.data.push({ name: "X-Requested-With", value: "XMLHttpRequest" });

        method = options.type.toUpperCase();
        if (!isMethodProxySafe(method)) {
            options.type = "POST";
            options.data.push({ name: "X-HTTP-Method-Override", value: method });
        }

        $.ajax(options);
    }

    function validate(form) {
        var validationInfo = $(form).data(data_validation);
        return !validationInfo || !validationInfo.validate || validationInfo.validate();
    }

    $(document).on("click", "a[data-ajax=true]", function (evt) {
        evt.preventDefault();
        asyncRequest(this, {
            url: this.href,
            type: "GET",
            data: []
        });
    });

    $(document).on("click", "form[data-ajax=true] input[type=image]", function (evt) {
        var name = evt.target.name,
            target = $(evt.target),
            form = $(target.parents("form")[0]),
            offset = target.offset();

        form.data(data_click, [
            { name: name + ".x", value: Math.round(evt.pageX - offset.left) },
            { name: name + ".y", value: Math.round(evt.pageY - offset.top) }
        ]);

        setTimeout(function () {
            form.removeData(data_click);
        }, 0);
    });

    $(document).on("click", "form[data-ajax=true] :submit", function (evt) {
        var name = evt.currentTarget.name,
            target = $(evt.target),
            form = $(target.parents("form")[0]);

        form.data(data_click, name ? [{ name: name, value: evt.currentTarget.value }] : []);
        form.data(data_target, target);

        setTimeout(function () {
            form.removeData(data_click);
            form.removeData(data_target);
        }, 0);
    });

    $(document).on("submit", "form[data-ajax=true]", function (evt) {
        var clickInfo = $(this).data(data_click) || [],
            clickTarget = $(this).data(data_target),
            isCancel = clickTarget && clickTarget.hasClass("cancel");
        evt.preventDefault();
        if (!isCancel && !validate(this)) {
            return;
        }
        asyncRequest(this, {
            url: this.action,
            type: this.method || "GET",
            data: clickInfo.concat($(this).serializeArray())
        });
    });
}(jQuery));