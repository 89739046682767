const _floatDefaultPrecision = 1;

ko.bindingHandlers.float = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		var allBindings = allBindingsAccessor(),
			precision = allBindings.precision,
			suffix = allBindings.suffix,
			increasePrevisionForLowValues = allBindings.increasePrecisionForLowValues;

		if (precision !== undefined) {
			$(element).data('precision', precision);
		}
		if (suffix !== undefined) {
			$(element).data('suffix', suffix);
		}
		if (increasePrevisionForLowValues) {
			$(element).data('increasePrecisionForLowValues', true);
		}
	},
	update: function (element, valueAccessor) {
		var $element = $(element),
			value = ko.unwrap(valueAccessor()),
			precision = $element.data('precision'),
			suffix = $element.data('suffix') || '',
			increasePrecisionForLowValues = $element.data('increasePrecisionForLowValues') || false,
			formattedValue = '';
		if (precision === undefined) {
			precision = _floatDefaultPrecision;
		}

		if (value !== null) {
			if (!isNaN(value)) {
				if (increasePrecisionForLowValues && precision === 0 && value < 10 && value > -10) {
					precision++;
				}

				if (precision === 0) {
					formattedValue = "" + Math.round(value).toFixed(0) + suffix;
				} else {
					formattedValue = "" + value.toFixed(precision) + suffix;
				}
			} else {
				formattedValue = "-";
			}
		}

		ko.bindingHandlers.html.update(element, () => formattedValue);
	},
};
ko.virtualElements.allowedBindings['float'] = true;