ko.bindingHandlers.currency = {
	update: (element, valueAccessor) => {
		let value = ko.unwrap(valueAccessor());
		if (typeof value === 'string') {
			value = parseFloat(value);
		}
		if (typeof value === 'number') {
			const formattedValue = value.format(2);
			ko.bindingHandlers.text.update(element, () => "£" + formattedValue);
		}
	}
};