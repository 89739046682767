import 'regenerator-runtime/runtime';
import 'knockout';
import 'knockout-mapping';
import 'knockout.validation';
import 'ko.plus';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'lodash';
import '../lib/jquery.linkify.js';
import 'timeago'
import '../lib/jquery.timeago.lang.js';
import '../lib/jquery.linkWholeRows.js';
import '../lib/jquery.unobtrusive-ajax.custom.js';
import '../lib/jquery.validate.unobtrusive.dynamic.js';
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
import './Common';
import './Validation';
import 'jquery-hotkeys';
import 'blueimp-gallery';
import 'blueimp-gallery/js/jquery.blueimp-gallery.js';

// Common bindings
import './KoBindings/bargraph';
import './KoBindings/bool';
import './KoBindings/commandbutton';
import './KoBindings/currency';
import './KoBindings/date';
import './KoBindings/datetime';
import './KoBindings/float';
import './KoBindings/form';
import './KoBindings/fromnow';
import './KoBindings/numberwithsuffix';
import './KoBindings/percent';
import './KoBindings/popover';
import './KoBindings/pressure';
import './KoBindings/progress';
import './KoBindings/radio';
import './KoBindings/radiotab';
import './KoBindings/rangeslider';
import './KoBindings/relativedateandtime';
import './KoBindings/select2';
import './KoBindings/showmodal';
import './KoBindings/splittext';
import './KoBindings/temperature';
import './KoBindings/timespan';
import moment from 'moment';

moment.locale('en-gb');

if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
	//On Custom Class
	if ($('.c-overflow')[0]) {
		const container = document.querySelector('.c-overflow');

		const ps = new PerfectScrollbar(container, {
			wheelPropagation: true,
			suppressScrollX: true
		});
	}
}

window.showModal = (modalName: string) => {
	const element = document.getElementById(modalName);

	$(element).on('show.bs.modal', (e) => {
		var clipboard = new ClipboardJS('.copy-text', { container: element });
		clipboard.on('success', window["clipboard_onsuccess"]);
	});

	$(element).on('shown.bs.modal', (e) => {
		// $("input[type=text],select", $(element)).first().focus();
	});

	$(element).modal('show');
};

$(document).ready(() => {
	window.OnRenderComplete();

	const layoutStatus = localStorage.getItem('layout-status');
	if (parseInt(layoutStatus) === 0) {
		$('body').removeClass('show-sidebar');
	}

	// Main Search Bar
	function ShowSearch() {
		$('#header').addClass('search-toggled');
		$('#top-search-wrap input').focus();
	}
	function CloseSearch() {
		$('#header').removeClass('search-toggled');
	}

	$('body').on('click', '#top-search > a', function (e) {
		e.preventDefault();
		ShowSearch();
	});
	$('body').on('click', '#top-search-close', function (e) {
		e.preventDefault();
		$('#header').removeClass('search-toggled');
	});

	$(document).bind('keyup', '/', (e) => ShowSearch());

	$(document).on('keydown', '.tt-input:input', function (event) {
		if (event.key == "Enter") {
			event.preventDefault();
		}
	});

	window.loading = ko.observable(0);
	window.loading.subscribe((b) => {
		if (b === 0) {
			$('body').removeClass('loading');
		} else {
			$('body').addClass('loading');
		}
	});

	// Sidebar
	$('body').on('click', '#menu-trigger', '#chat-trigger', function (e) {
		e.preventDefault();
		var x = $(this).data('trigger');
		$(x).toggleClass('toggled');
		$(this).toggleClass('open');

		// Close opened submenus
		$('.sub-menu.toggled').not('.active').each(() => {
			$(this).removeClass('toggled');
			$(this).find('ul').hide();
		});

		$('.profile-menu .main-menu').hide();

		var elem = '#sidebar',
			elem2 = '#menu-trigger';
		$('#header').addClass('sidebar-toggled');

		// When clicking outside, close...
		if ($('#header').hasClass('sidebar-toggled')) {
			$(document).on('click', (e) => {
				if (($(e.target).closest(elem).length === 0) && ($(e.target).closest(elem2).length === 0)) {
					setTimeout(() => {
						$(elem).removeClass('toggled');
						$('#header').removeClass('sidebar-toggled');
						$(elem2).removeClass('open');
					});
				}
			});
		}
	});
});