export namespace Formatter {
	function formatBytesCore(bytes: number, si: boolean) {
		return formatNumberWithSuffix(bytes, si, 'B');
	}

	export function formatNumberWithSuffix(number: number, si: boolean, unit: string) {
		var result = formatNumberWithSuffixCore(number, si, unit);
		if (result[0] === null) { return null; }

		if (result[1]) {
			return `${result[0]} <span class="unit">${result[1]}</span>`
		} else {
			return result[0];
		}
	}

	export function formatNumberWithSuffixText(number: number, si: boolean, unit: string) {
		var result = formatNumberWithSuffixCore(number, si, unit);
		if (result[0] === null) { return null; }

		if (result[1]) {
			return `${result[0]} ${result[1]}`
		} else {
			return result[0];
		}
	}

	function formatNumberWithSuffixCore(number: number, si: boolean, unit: string) {
		if (number === null) {
			return [null, null];
		}

		var sign = 1;
		if (number < 0) {
			sign = -1;
			number *= -1;
		}

		var m = si ? 1000 : 1024,
			exp = Math.floor(Math.log(number) / Math.log(m)),
			formattedUnit = (si ? "kMGTPE" : "KMGTPE").charAt(exp - 1),
			n = number / Math.pow(m, exp);

		number *= sign;
		n *= sign;

		if (Math.abs(number) < m) {
			return [number.format(0), unit];
		}

		return [n.format(1), `${formattedUnit}${unit}`];
	}

	export function formatBytes(bytes: number) {
		return formatBytesCore(bytes, false);
	}

	export function formatBytesSI(bytes: number) {
		return formatBytesCore(bytes, true);
	}

	export function formatSIPlain(d: number) {
		var result = formatNumberWithSuffixCore(d, true, '');
		if (result[0] === null) { return null; }

		if (result[1]) {
			return `${result[0]} ${result[1]}`
		} else {
			return result[0];
		}
	}
}