import { Command } from "../../@types/ko.plus";
import { AjaxForms } from "../AjaxForms";

ko.bindingHandlers.form = {
	init: (element, valueAccessor) => {
		const command: Command = valueAccessor();
		const $element = $(element);

		if (command) {
			var summaryElement = AjaxForms.FindOrCreateValidationElement(element);

			command.done((data) => {
				if (data !== undefined) {
					AjaxForms.ClearValidationErrors(element, summaryElement);
				}
			});

			command.fail((response, status, statusText) => {
				AjaxForms.ClearValidationErrors(element, summaryElement);
				AjaxForms.HandleErrorFormResponse(response, element, summaryElement);
			});

			command.isRunning.subscribe(newValue => {
				if (newValue) {
					AjaxForms.ClearValidationErrors(element, summaryElement);
				}
			})
		}
	}
}