import { guid } from "../Utils/IdFactory";

ko.bindingHandlers.progress = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		var $element = $(element),
			allBindings = allBindingsAccessor(),
			showLabelPct = ko.unwrap(allBindings.showLabelPct);

		var bar = $('<div/>', { 'class': 'progress-bar' });
		$element
			.attr('id', guid())
			.addClass('progress')
			.append(bar);

		if (showLabelPct) {
			var label = $('<span class="progresslabel" />');
			//var label = $('<span />', { 'data-bind': 'text: ' + valueAccessor() });
			$element.append(label);
		}
	},
	update: function (element, valueAccessor, allBindingsAccessor) {
		var $element = $(element),
			allBindings = allBindingsAccessor(),
			showLabelPct = ko.unwrap(allBindings.showLabelPct);

		var value = ko.unwrap(valueAccessor());
		if (typeof value === 'number') {
			value = `${value}%`;
		}

		var bar = $element.children('div.progress-bar');
		bar.css({ 'width': value });

		if (showLabelPct) {
			var label = $element.children('span.progresslabel');
			label.text(value);
		}
	}
};