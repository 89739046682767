// From: https://github.com/cruikshj/knockout-select2/blob/master/src/knockout-select2.js

function knockout_select2_triggerChangeQuietly(element, binding) {
	var isObservable = ko.isObservable(binding);
	var originalEqualityComparer;
	if (isObservable) {
		originalEqualityComparer = binding.equalityComparer;
		binding.equalityComparer = function () { return true; };
	}
	$(element).trigger('change');
	if (isObservable) {
		binding.equalityComparer = originalEqualityComparer;
	}
}

function knockout_select2_init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
	var bindingValue = ko.unwrap(valueAccessor());
	var allBindings = allBindingsAccessor();
	var ignoreChange = false;
	var dataChangeHandler = null;
	var subscription = null;

	$(element).on('select2:selecting select2:unselecting', function () {
		ignoreChange = true;
	});
	$(element).on('select2:select select2:unselect', function () {
		ignoreChange = false;
	});

	if (ko.isObservable(allBindings.value)) {
		subscription = allBindings.value.subscribe(function (value) {
			if (ignoreChange) return;
			knockout_select2_triggerChangeQuietly(element, allBindings.value);
		});
	} else if (ko.isObservable(allBindings.selectedOptions)) {
		subscription = allBindings.selectedOptions.subscribe(function (value) {
			if (ignoreChange) return;
			knockout_select2_triggerChangeQuietly(element, allBindings.selectedOptions);
		});
	}

	// Provide a hook for binding to the select2 "data" property; this property is read-only in select2 so not subscribing.
	const dataBindingName = 'select2Data';
	if (ko.isWriteableObservable(allBindings[dataBindingName])) {
		dataChangeHandler = function () {
			if (!$(element).data('select2')) return;
			allBindings[dataBindingName]($(element).select2('data'));
		};
		$(element).on('change', dataChangeHandler);
	}

	if (typeof bindingValue !== 'object') {
		bindingValue = {};
	}

	if (!('theme' in bindingValue)) {
		bindingValue['theme'] = 'bootstrap4';
	}

	if ('multiple' in element.attributes) {
		bindingValue['closeOnSelect'] = false;
	}

	// Are we in a modal?
	const modalParent = element.closest('.modal');
	if (modalParent) {
		bindingValue['dropdownParent'] = $(modalParent);
	}

	// Apply select2
	$(element).select2(bindingValue);

	// Destroy select2 on element disposal
	ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
		$(element).select2('destroy');
		if (dataChangeHandler !== null) {
			$(element).off('change', dataChangeHandler);
		}
		if (subscription !== null) {
			subscription.dispose();
		}
	});
}

ko.bindingHandlers.select2 = {
	init: (...args) => {
		setTimeout(() => knockout_select2_init(...args))
	}
}