import { Formatter } from "../Formatter";

ko.bindingHandlers.numberWithSuffix = {
	update: (element, valueAccessor, allBindingsAccessor) => {
		var value = ko.unwrap(valueAccessor()), formattedValue = '', unit = '';

		if (value !== undefined) {
			unit = ko.unwrap(allBindingsAccessor().unit) || '';
			formattedValue = Formatter.formatNumberWithSuffix(value, true, unit);
		}

		ko.bindingHandlers.html.update(element, () => formattedValue);
	}
}

ko.bindingHandlers.numberWith1024Suffix = {
	update: (element, valueAccessor, allBindingsAccessor) => {
		var value = ko.unwrap(valueAccessor()), formattedValue = '', unit = '';

		if (value !== undefined) {
			unit = ko.unwrap(allBindingsAccessor().unit) || '';
			formattedValue = Formatter.formatNumberWithSuffix(value, false, unit);
		}

		ko.bindingHandlers.html.update(element, () => formattedValue);
	}
}