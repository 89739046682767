// Redefine the loadingWhen binding from ko.plus to use our button style
ko.bindingHandlers.loadingWhen = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		var $element = $(element);

		if ($('> div.spinner-border', $element).length === 0) {
			const spinner = $('<div class="spinner-border spinner-border-sm"></div>');
			$element.prepend(spinner);
			spinner.hide();
		}

		// If the inner text is currently loose, move it into a span so we can find it later
		$element.contents().filter((index, element) => element.nodeType === 3).wrap("<span>");
	},
	update: function (element, valueAccessor, allBindingsAccessor) {
		var isLoading = ko.utils.unwrapObservable(valueAccessor());

		$(element).each(function (index, el) {
			var $el = $(el),
				elementState = $el.data('isLoading'),
				span = $('> span', $el),
				icon = $('> i', $el),
				spinner = $('> div.spinner-border', $el);

			// Find the text to replace with
			var loadingText = allBindingsAccessor().loadingText || $el.data('loading-text') || 'Loading...';
			if (loadingText.length && loadingText[0] !== ' ') {
				loadingText = ' ' + loadingText;
			}

			if (isLoading && !elementState) {
				// Put the placeholder text in
				span.data('origText', span.html());
				span.html(loadingText);

				// Change the icon over, and show it if necessary
				icon.hide();
				spinner.show();

				// Indicate we've changed
				$el.data('isLoading', true);
			} else if (!isLoading && elementState) {
				var origText = span.data('origText');

				// Restore the original text
				span.html(origText);
				span.data('origText', undefined);

				// Restore the original icon
				spinner.hide();
				icon.show();

				$el.data('isLoading', false);
			}
		});
	}
};