import moment from "moment";

ko.bindingHandlers.datetime = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		var $element = $(element),
			allBindings = allBindingsAccessor();

		if ($element.is('input')) {
			var pattern = allBindings.format || 'DD/MM/YYYY HH:mm:ss',
				eventsToCatch = ["change"],
				propertyChangeFired = false,
				elementValueBeforeEvent = null;

			var valueUpdateHandler = function () {
				var modelValue = valueAccessor();
				var elementValue = ko.selectExtensions.readValue(element);

				// Convert element value to the date format we're expecting
				if (elementValue) {
					elementValue = moment(elementValue, pattern).format('YYYY-MM-DD HH:mm:ss');
				} else {
					elementValue = null;
				}
				modelValue(elementValue);
			};

			// Workaround for https://github.com/SteveSanderson/knockout/issues/122
			// IE doesn't fire "change" events on textboxes if the user selects a value from its autocomplete list
			//var ieAutoCompleteHackNeeded = ko.utils.ieVersion && element.tagName.toLowerCase() === "input" && element.type === "text"
			//	&& element.autocomplete !== "off" && (!element.form || element.form.autocomplete !== "off");
			//if (ieAutoCompleteHackNeeded && ko.utils.arrayIndexOf(eventsToCatch, "propertychange") === -1) {
			//	ko.utils.registerEventHandler(element, "propertychange", function () { propertyChangedFired = true; });
			//	ko.utils.registerEventHandler(element, "focus", function () { propertyChangedFired = false; });
			//	ko.utils.registerEventHandler(element, "blur", function () {
			//		if (propertyChangedFired) {
			//			valueUpdateHandler();
			//		}
			//	});
			//}

			ko.utils.arrayForEach(eventsToCatch, function (eventName) {
				ko.utils.registerEventHandler(element, eventName, valueUpdateHandler);
			});
		}
	},
	update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var value = ko.unwrap(valueAccessor());
		var allBindings = allBindingsAccessor();

		var pattern = allBindings.format || 'DD/MM/YYYY HH:mm:ss';

		var output = '';
		if (value !== null && value !== undefined && value.length > 0) {
			output = moment(value).format(pattern);
		}

		if ($(element).is('input')) {
			$(element).val(output);
		} else {
			$(element).text(output);
		}
	}
};