ko.bindingHandlers.barGraph = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		var $element = $(element),
			allBindings = allBindingsAccessor(),
			barGraphLabel = allBindings.barGraphLabel,
			showLabelPct = ko.unwrap(allBindings.showLabelPct);

		$element
			.addClass('progress')
			.append($('<div/>').addClass('progress-bar'));

		if (barGraphLabel) {
			$element.append($('<span />').addClass('value'));
		} else if (showLabelPct) {
			$element.append($('<span />').addClass('value').addClass('percent'));
		}
	},
	update: function (element, valueAccessor, allBindingsAccessor) {
		var $element = $(element),
			value = ko.unwrap(valueAccessor()),
			allBindings = allBindingsAccessor(),
			barGraphLabel = allBindings.barGraphLabel,
			showLabelPct = ko.unwrap(allBindings.showLabelPct),
			barGraphWarningFrom = ko.unwrap(allBindings.barGraphWarningFrom),
			barGraphDangerFrom = ko.unwrap(allBindings.barGraphDangerFrom),
			$bar = $element.find('.progress-bar');

		var stringValue = "" + value;
		if (value && (typeof value === 'number' || !value.endsWith('%'))) {
			stringValue = value + '%';
		}

		$bar.css('width', stringValue);
		
		if (barGraphLabel) {
			$element.find('.value').text(ko.unwrap(barGraphLabel));
		} else if (showLabelPct) {
			$element.find('.value').text(stringValue);
		}

		if (barGraphWarningFrom || barGraphDangerFrom) {
			let valueToCompare = allBindings.barGraphColourSource
				? ko.unwrap(allBindings.barGraphColourSource)
				: value;
			if (valueToCompare && typeof valueToCompare === 'string' && valueToCompare.endsWith('%')) {
				valueToCompare = valueToCompare.substr(0, valueToCompare.length - 1);
			}
			valueToCompare = parseFloat(valueToCompare);

			var shouldBeWarning = (barGraphWarningFrom && valueToCompare >= barGraphWarningFrom) || false,
				shouldBeDanger = (barGraphDangerFrom && valueToCompare >= barGraphDangerFrom) || false;
			if (shouldBeDanger) { shouldBeWarning = false; }

			$bar.toggleClass('bg-warning', shouldBeWarning);
			$bar.toggleClass('bg-danger', shouldBeDanger);
		}
	}
};