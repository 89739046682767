import 'rangeslider.js';
import { subscribe } from 'toastr';

interface IRangeSliderBindingOptions {
	Value: KnockoutObservable<number>;
	Min: KnockoutSubscribable<number> | number;
	Max: KnockoutSubscribable<number> | number;
}

ko.bindingHandlers.rangeslider = {
	init: function (element, valueAccessor, allBindingsAccessor) {
		var allBindings = allBindingsAccessor();

		// Init rangeslider
		var options = allBindings.sliderOptions || {};
		if (options.polyfill === undefined) {
			options.polyfill = false;
		}

		var subscriptions: KnockoutSubscription[] = [];

		let minBinding: ko.Subscribable<number> = null;
		let maxBinding: ko.Subscribable<number> = null;

		if (!ko.isObservable(valueAccessor())) {
			const valueOptions: IRangeSliderBindingOptions = ko.unwrap(valueAccessor());
			if (ko.isObservable(valueOptions.Min)) {
				minBinding = valueOptions.Min;
			}

			if (ko.isObservable(valueOptions.Max)) {
				maxBinding = valueOptions.Max;
			}
			options.onSlide = (position, newValue) => valueOptions.Value(newValue);
		} else {
			const allBindings = allBindingsAccessor();
			if (allBindings.attr && ko.isObservable(allBindings.attr.min)) {
				minBinding = allBindings.attr.min;
			}

			if (allBindings.attr && ko.isObservable(allBindings.attr.max)) {
				maxBinding = allBindings.attr.max;
			}

			options.onSlide = (position, newValue) => valueAccessor()(newValue);
		}

		if (minBinding) {
			subscriptions.push(minBinding.subscribe(newValue => {
				$(element).rangeslider('update', true);
			}));
		}

		if (maxBinding) {
			subscriptions.push(maxBinding.subscribe(newValue => {
				$(element).rangeslider('update', true);
			}));
		}

		$(element).rangeslider(options);
		ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
			subscriptions.forEach(s => s.dispose());
			$(element).rangeslider("destroy");
		});
	},
	update: function (element, valueAccessor) {
		var value: number;
		if (ko.isObservable(valueAccessor())) {
			value = ko.unwrap(valueAccessor());
		} else {
			var valueOptions = <IRangeSliderBindingOptions>ko.unwrap(valueAccessor());
			value = ko.unwrap(valueOptions.Value);
		}
		// !!!!! Error when setting max to 0 then back

		if (isNaN(value)) {
			value = 0;
		}
		$(element).val(value).change();
	}
};