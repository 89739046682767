jQuery.timeago.settings.strings = {
    prefixAgo: null,
    prefixFromNow: null,
    suffixAgo: "ago",
    suffixFromNow: "from now",
    seconds: "less than a minute",
    minute: "1 minute",
    minutes: "%d minutes",
    hour: "1 hour",
    hours: "about %d hours",
    day: "a day",
    days: "%d days",
    month: "1 month",
    months: "%d months",
    year: "1 year",
    years: "%d years",
    wordSeparator: " ",
    numbers: []
};