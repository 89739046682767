ko.bindingHandlers.radioTab = {
	init: function (element, valueAccessor, allBindings) {
		var $tabs,
			$element,
			observable = valueAccessor(),
			elementBindings;

		if (!ko.isWriteableObservable(observable)) {
			throw "You must pass an observable or writeable computed";
		}

		$element = $(element);
		if ($element.is("a")) {
			$tabs = $element;
		} else {
			$tabs = $("a", $element);
		}

		elementBindings = allBindings();
		$tabs.each(function () {

			const a = this;
			const $a = $(a);
			const radioValue = elementBindings.radioValue || $a.attr("data-value") || $a.attr("value") || $a.text();

			$a.on("click", () => observable(ko.unwrap(radioValue)));

			return ko.computed({
				disposeWhenNodeIsRemoved: a,
				read: () => $a.toggleClass("active", observable() === ko.unwrap(radioValue))
			});
		});
	}
};