// http://stackoverflow.com/a/9537696/163495
// NB: This library does not work with Knockout Virtual Elements (<!-- ko -->).  Use data-bind on an element instead.
(function ($) {
    $.fn.linkWholeRows = function () {
        // for each object
        return this.each(function () {
            // for each row
            $(this).find('tbody tr').each(function () {
                // get the first link's href
                var firstLink = $(this).find('td > a')
                var href = firstLink.attr('href');
                // if none found then
                if (href === undefined) {
                    // Look for a checkbox instead...
                    if ($(this).find(':checkbox').length) {
                        // Add behaviour to use the row to select the whole row
                        $(this)
                            .addClass('tr-checkboxrow')
                            .click(function (event) {
                                if (event.target.type !== 'checkbox') {
                                    $(':checkbox', this).trigger('click');
                                }
                            });
                    }
                    return true; // continue
                }

                // wrap all cells with links that do not already have a link
                $(this).children()
                    .not(':has(a)')
                    .not(':has(button)')
                    .not('.no-fullrowselect')
                    .not('.e-gridchkbox')
                    .each(function () {
                    var newElement = $('<a href="' + href + '">');

                    // Copy all of the data- attributes from the original anchor, so we still get any attached behaviour
                    $.each(firstLink.prop('attributes'), function () {
                        if (this.name.startsWith('data-') || this.name === 'target') {
                            newElement.attr(this.name, this.value);
                        }
                    });

                    if ($(this).contents().length === 0) {
                        $(this).append("&nbsp;");
                    }

                    $(this).contents().wrapAll(newElement);
                });

                //// apply the row's height to all links
                //// in case that the cells' content have different heights
                //var height = $(this).children().css('height');
                //$(this).find('td > a').each(function () {
                //    $(this).css('height', height);
                //    // do not forget to apply display:block to the links
                //    // via css to make it work properly
                //});
            }); // each row

            $(this).addClass("table-fullrowselect-applied");
        }); // each object
    };
})(jQuery);